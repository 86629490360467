import React from 'react';
import cs from './cloud-service.jpg';
import sa from './server-adminstrator-service.jpg';
import ds from './desktop-service.jpg';
import ns from './network-service.jpg';
import devs from './devops-service.jpg';


function Services() {
  return (
    <section id="services">
      <h2>Our Services</h2>
      <ul>
        <li>
          <img src={cs} alt="Cloud Service" />
          <h3>Cloud Service</h3>
          <h4>Empowering Your Vision, Cloud by Cloud.</h4>
          <h4>At REAL TIME IT SOLUTIONS, we specialize in delivering top-notch cloud services that empower your business to soar to new heights. From seamless cloud migrations to robust security solutions, we've got you covered every step of the way. Ready to transform your IT infrastructure and embrace the cloud revolution? Let us be your trusted partner on this journey to success!</h4>
        </li>
        <li>
          <img src={sa} alt="Server Administration" />
          <h3>Server Administration</h3>
          <h4>Strengthening Your Core, One Server at a Time.</h4>
          <h4>At REAL TIME IT SOLUTIONS, we understand that your servers are the backbone of your business operations. That's why we offer top-tier Server Administration services to keep your systems running smoothly and securely. Whether it’s maintenance, monitoring, or troubleshooting, our experts ensure your servers perform at their best, so you can focus on growing your business.</h4>
        </li>
        <li>
          <img src={ds} alt="Desktop Solution" />
          <h3>Desktop Solution</h3>
          <h4>Maximizing Productivity, One Desktop at a Time.</h4>
          <h4>At REAL TIME IT SOLUTIONS, we know that seamless desktop performance is crucial for your team's productivity. Our Desktop Solutions services ensure that your workstations are always running smoothly, with fast, reliable support whenever you need it. From troubleshooting to software updates and system optimization, we’re here to keep your business moving forward without a hitch.</h4>
        </li>
        <li>
          <img src={ns} alt="Network Solution" />
          <h3>Network Solution</h3>
          <h4>Connecting Your Business to the Future</h4>
          <h4>At REAL TIME IT SOLUTIONS, we specialize in creating secure, efficient, and scalable network infrastructures that power your business. Whether you need to optimize your current setup or design a new network from scratch, our Networking Services ensure your connectivity is seamless, secure, and tailored to your needs. Stay connected, stay ahead.</h4>
        </li>
        <li>
          <img src={devs} alt="DevOps Solution" />
          <h3>DevOps Solution</h3>
          <h4>Bridging Development and Operations for Maximum Efficiency</h4>
          <h4>At REAL TIME IT SOLUTIONS, we bring together the power of development and operations with our expert DevOps Services. Streamline your software delivery pipeline, enhance collaboration, and achieve faster, more reliable releases. Our team is dedicated to implementing efficient DevOps practices that propel your business forward.</h4>
        </li>
      </ul>
    </section>
  );
}

export default Services;
