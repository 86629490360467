import React from 'react';
import contact from './contact-us.jpg';
function Contact() {
  return (
    <section id="contact">
      <h2>Contact Us</h2>
      <div className="contact-info">
        <p>AMAZON HALL</p>
        <p>NEAR AMRUTA HOSPITAL</p>
        <p>150ft RING ROAD, RAJKOT.</p>
        <p>360005</p>
        <p>Phone: +91-9714095640</p>
        <p>Email: <a href='mailto:administrator@rtits.in' className='email-link'>enquiry.com</a>
        </p>
        <img src={contact} alt='contact-us'/>
      </div>
    </section>
  );
}

export default Contact;
