import React from 'react';
//import home from './rtits.jpg';
import myVideo from './rtits.mp4';

function Home() {
  return (
    <section id="home">
      <h2>Welcome to REAL TIME IT SOLUTIONS</h2>
      <h2>Transform Your IT Experience with REAL TIME IT SOLUTIONS</h2>
      <h4>At REAL TIME IT SOLUTIONS, we redefine IT services and consultancy to drive your business towards success. Our comprehensive suite of services ensures that your IT infrastructure is robust, secure, and optimized for peak performance. From cloud innovations to meticulous server and network administration, we’ve got the expertise to handle it all.</h4>
      
      <video 
        width="1040" 
        height="560" 
        autoPlay 
        muted 
        loop 
        playsInline
        controls
        style={{ display: 'block', margin: '0 auto' }} // Center the video
      >
        <source src={myVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </section>
  );
}

export default Home;
