import React from 'react';
import threads from './images.png'

function Footer() {
  return (
    <footer>
      <p>Connect with us on social media:</p>
      <div className="social-media">
        <a href="https://www.facebook.com/share/CbDLburt5GFFAsBd/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook-f"></i>
        </a>
        <a href="https://www.twitter.com/YourProfile" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="https://www.instagram.com/rtits119?igsh=eTdhbmtyYTJoaWdk" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="https://www.linkedin.com/company/real-time-it-solutions-rajkot" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-linkedin-in"></i>
        </a>
        <a href="https://www.threads.net/@rtits119?xmt=AQGzMr5bI1aGPT3T6iJVsHjpDHO5eu7zu2G6lOahFVUPur0" target="_blank" rel="noopener noreferrer">
          <img src={threads} alt="Follow me on Threads" style={{ width: '24px', height: '24px', verticalAlign: 'middle' }} />
        </a>
      </div>
      <p>&copy; 2024 REAL TIME IT SOLUTIONS. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
